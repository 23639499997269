<template>
    <div class="mentor detail mw1260">
        <div class="top">
            <div class="t-header">
                <div class="title">
                    우리 아이들의 꿈을 응원하는<br>
                    <span>아빠미소멘토단</span>
                </div>
            </div>
        </div>
        <div class="content-wrap">

            <div class="top clear">
                <div class="info" v-if="hasWriter()">
                    <div class="profile" :style="`cursor:pointer`" @click="openMemberInfoModal(boardData.Writer.mb_no)">
                        <img :src="returnProfileUrl()">
                    </div>
                    {{boardData.Writer.mb_name}}
                </div>
                <div class="date">{{returnDateFormat(boardData.created_at)}}</div>
            </div>


            <div class="content">
                <div class="title">
                    {{boardData.wr_subject}}
                </div>
                <div class="photo-wrap" v-if="hasImageFile()">
                    <div class="photo" v-for="file in boardData.Files" :key="`commuFile${file.a_idx}`">
                        <img :src="returnImageUrl(file)" class="img" @click="openImageInfoModal(file.org_url)">
                    </div>
                </div>
                <div class="letter-info">
                    <div class="write" v-html="replaceBr(boardData.wr_content)"></div>
                </div>
            </div>
        </div>

        <div class="d-footer clear">
            <div class="f-info">
                <div class="like">추천
                    <board-like-component :boardType="'mentorLike'" :boardData="boardData"></board-like-component>
                </div>
                <div class="comment">댓글 <span>{{returnCount(boardData.CommentCount, 9999)}}</span></div>
            </div>
            <div class="button-wrap" v-if="checkMyBoard()">
                <button class="btn" @click="openDeleteConfirm()">삭제</button>
                <button class="btn" @click="goModify()">수정</button>
                <button class="btn list" @click="$router.back()">목록</button>
            </div>
            <div class="button-wrap" v-else-if="checkAdmin()">
                <button class="btn" @click="openDeleteConfirm()">삭제</button>
                <button class="btn list" @click="$router.back()">목록</button>
            </div>
            <div class="button-wrap" v-else>
                <button class="btn" @click="openDeclarationConfirm">신고</button>
                <button class="btn list" @click="$router.back()">목록</button>
            </div>
        </div>
        <div class="comment-wrap">
            <comment-component :boardType="'mentorComment'" :boardKey="boardKey"></comment-component>
        </div>
        <image-detail-modal :imageUrl="imageUrl"></image-detail-modal>

    </div>
</template>

<script>
    import vuxAlert from "@/mixins/vux-alert";
    import util from "@/mixins/util";
    import {mapState, mapGetters} from "vuex"
    import CommentComponent from "@/components/board/comment/CommentComponent";
    import BoardLikeComponent from "@/components/board/BoardLikeComponent";
    import EventBus from "@/utils/event-bus";
    import ImageDetailModal from "@/components/modal/ImageDetailModal";
    import imageOption from "@/mixins/imageOption";

    export default {
        name: "BoardDetailMentorCommuLayout",
        mixins: [vuxAlert, imageOption],
        components: {
            CommentComponent,
            BoardLikeComponent,
            ImageDetailModal
        },
        inject: ['boardDelete', 'boardBlind'],
        props: {
            boardData: {
                type: Object,
                default: () => {
                    return {}
                },
            },
            categorys: {
                type: Array,
                default: () => {
                    return []
                },
            },
        },
        data() {
            return {
                boardKey: this.$route.params.wr_id,
                imageUrl: '',

            }
        },
        beforeRouterEnter() {
        },
        created() {

        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            }),
        },
        methods: {
            returnDateFormat(date) {
                if (!date) {
                    return ''
                }
                return this.$moment(date).format('YYYY-MM-DD HH:mm')
            },
            replaceBr(content) {
                if (util.isEmpty(content)) {
                    return '';
                }
                return content.replace(/(\n|\r\n)/g, '<br>');
            },
            returnProfileUrl() {
                return util.isEmpty(this.boardData.Writer) || util.isEmpty(this.boardData.Writer.Profile) || this.boardData.Writer.Profile.length === 0 ? '' : `${this.boardData.Writer.Profile[0].org_url}?${this.setImageOptions(121, 121, 'png')}`;
            },
            returnCount(count, maxCount) {
                if (maxCount < count) {
                    return maxCount + '+';
                }
                return count
            },
            returnImageUrl(file) {
                return !file.hasOwnProperty('org_url') ? "" : `${file.org_url}?${this.setImageOptions(300, 300, 'png')}`
            },
            hasImageFile() {
                return this.boardData.hasOwnProperty('Files') && this.boardData.Files.length > 0 && this.boardData.Files[0].a_idx;
            },
            hasWriter() {
                return this.boardData.hasOwnProperty('Writer') && !util.isEmpty(this.boardData.Writer);
            },

            checkMyBoard() {
                return this.hasWriter() && this.boardData.Writer.mb_no === this.UserInfo.mb_no;
            },
            checkAdmin() {
                return this.UserInfo.mb_type === 0
            },
            goModify() {
                // let nowUrl = this.$route.path;
                // this.$router.push(`${nowUrl}/update`);
                this.$router.push(`/mentor/${this.boardData.wr_id}/update`);

            },
            openDeleteConfirm() {
                this.createConfirm({
                    title: '',
                    content: '정말 삭제하시겠습니까?',
                    confirmText: '확인',
                    cancelText: '취소',
                    confirm: () => {
                        if (!util.isEmpty(this.boardDelete)) {
                            this.boardDelete(this.boardData.wr_id);
                        }
                    }
                })
            },
            openDeclarationConfirm(boardData) {
                this.declarateConfirm({
                    comment: boardData,
                    confirm: this.boardBlind
                })
            },
            openMemberInfoModal(mb_no) {
                EventBus.$emit('openMemberInfoModal', mb_no)
            },
            openImageInfoModal(url) {
                this.imageUrl = url;
                this.$nextTick(() => {
                    this.$modal.show('image-detail-modal');
                })

            },
        },
        watch: {},
    }
</script>

<style scoped>

</style>